/* website: 4557-jaguarbrossard2
 * created at 2025-01-17 14:00 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/jaguar.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr{
        &.dealer__Jaguar{
            .sr-progressBar-bg-title.is-open{
                color: #3C3C3B;
            }
        }
    }
}

.widget-sr{
    &.dealer__Jaguar{
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            padding: 15px 14px !important;
            text-transform: uppercase !important;
        }
        .sr-button-1,
        .sr-button-2,
        .sr-button-outline-1,
        .sr-button-outline-2{
            box-shadow: none!important;
        }
        .delivery-options-title.sr-subtitle-2,
        .checkout__payment-options-title.sr-subtitle-2{
            font-family: 'Gotham Bold', Arial, sans-serif;
        }
        .sr-paymentCalculator-background,
        .paymentCalculator__title--note.is-light,
        .paymentCalculator__payment--details.is-light,
        .paymentCalculator__rebates--term.is-light,
        .paymentCalculator__rebates--term.is-light .icon-promo2.is-util,
        #calculator-toggle-button.sr-button-1{
            color: #ffffff;
        }
        #calculator-toggle-button.sr-button-1{
            background: #3C3C3B;
        }
        .paymentCalculator__payment{
            .is-util,
            .is-util.is-bold{
                color: #fff;
            }
        }
        .paymentCalculator__interests{
            .paymentCalculator__interests--amount{
                color: #fff;
            }
        }
    }
}